import { GatsbyImage } from "gatsby-plugin-image";
import React, { useRef, useState, useEffect } from "react";
import CloudVideo from "../layouts/cloudvideo";
import GalleryView from "./galleryView";

const ExhibitList = ({ exhibit }) => {
  const { exhibitArray } = exhibit;
  const scrollContainerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentExhibit, setCurrentExhibit] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const updateURLHash = (exhibitIndex, itemIndex) => {
    const slug = exhibitArray[exhibitIndex].slug.current;
    window.location.hash = `${slug}-${itemIndex}`;
  };

  const handleNext = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        top: window.innerHeight,
        behavior: "smooth",
      });
    }
  };

  const handlePrevious = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        top: -window.innerHeight,
        behavior: "smooth",
      });
    }
  };

  const handleViewGallery = (exhibitIndex, itemIndex) => {
    setCurrentExhibit(exhibitArray[exhibitIndex]);
    setCurrentItemIndex(itemIndex);
    setIsOpen(true);
    updateURLHash(exhibitIndex, itemIndex);
  };

  const handleCloseGallery = () => {
    setIsOpen(false);
  };

  const showPreviousItem = () => {
    if (currentItemIndex > 0) {
      setCurrentItemIndex(currentItemIndex - 1);
    } else if (currentExhibit && currentIndex > 0) {
      setCurrentExhibit(exhibitArray[currentIndex - 1]);
      setCurrentItemIndex(
        exhibitArray[currentIndex - 1].videoImgGall.length - 1
      );
    }
    updateURLHash(currentIndex, currentItemIndex);
  };

  const showNextItem = () => {
    if (
      currentExhibit &&
      currentItemIndex < currentExhibit.videoImgGall.length - 1
    ) {
      setCurrentItemIndex(currentItemIndex + 1);
    } else if (currentExhibit && currentIndex < exhibitArray.length - 1) {
      setCurrentExhibit(exhibitArray[currentIndex + 1]);
      setCurrentItemIndex(0);
    }
    updateURLHash(currentIndex, currentItemIndex);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const scrollTop = scrollContainerRef.current.scrollTop;
        const newIndex = Math.round(scrollTop / window.innerHeight);
        setCurrentIndex(newIndex);
        updateURLHash(newIndex, 0);
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (hash) {
      const [slug, itemIndex] = hash.split("-");
      const exhibitIndex = exhibitArray.findIndex(
        (ex) => ex.slug.current === slug
      );
      if (exhibitIndex !== -1) {
        setCurrentIndex(exhibitIndex);
        setCurrentItemIndex(parseInt(itemIndex, 10));
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo({
            top: exhibitIndex * window.innerHeight,
            behavior: "smooth",
          });
        }
      }
    }
  }, [exhibitArray]);

  const currentItem = currentExhibit
    ? currentExhibit.videoImgGall[currentItemIndex]
    : null;

  return (
    <>
      {!isOpen ? (
        <>
          <div
            className="relative overflow-y-scroll snap-y snap-mandatory h-screen"
            key={exhibit.id}
            ref={scrollContainerRef}
          >
            {exhibitArray.map((ex, index) => (
              <div
                key={ex.id}
                id={ex.slug.current}
                className="relative h-[100vh] overflow-x-hidden scrollbar-hide flex flex-col items-center justify-center overflow-hidden snap-start"
              >
                <h3 className="w-full text-center uppercase text-xl font-helve my-[30px]">
                  {ex.title}
                </h3>

                  <div className="home_gallery_wrap">
                  <div className={currentIndex === index ? "home_gall_contain" : "styleGallery"}> 
                  {ex.videoImgGall.map((item, itemIndex) => (
                    <div
                      key={item._key}
                      className="flex-none my-auto cursor-pointer overflow-x-scroll"
                      onClick={() => handleViewGallery(index, itemIndex)}
                    >
                      {item._type === "mainImage" && (
                        <div>
                          <GatsbyImage
                            image={item.asset.gatsbyImageData}
                            key={item._key}
                            alt={item.alt}
                            className="w-[400px] h-[300px] lg:h-[600px] lg:w-[800px] object-contain my-auto"
                          />
                        </div>
                      )}
                      {item._type === "video" && (
                        <CloudVideo
                          className="w-auto h-[300px] lg:h-[600px] lg:w-auto object-contain"
                          cloudVideoUrl={item.url}
                          CloudVideoAlt={item.title}
                        />
                      )}
                    </div>
                  ))}
                </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-row justify-between cursor-pointer font-caslon sticky bottom-7 lg:bottom-4 px-[20px] lg:px-[80px]">
            <div onClick={() => handleViewGallery(currentIndex, 0)}>
              View Gallery
            </div>
            <div className="flex flex-row gap-[30px] lg:gap-[40px]">
              <button
                onClick={handlePrevious}
                className={currentIndex === 0 ? "text-gray-400" : "text-black"}
              >
                Previous
              </button>
              <button
                onClick={handleNext}
                className={
                  currentIndex === exhibitArray.length - 1
                    ? "text-gray-400"
                    : "text-black"
                }
              >
                Next
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Gallery View */}
          <GalleryView
            currentItem={currentItem}
            currentItemIndex={currentItemIndex}
            currentExhibit={currentExhibit}
            showPreviousItem={showPreviousItem}
            showNextItem={showNextItem}
            handleCloseGallery={handleCloseGallery}
          />
          <p>hi</p>
        </>
      )}
      {!isOpen ? (
        <h1 className="postTitle">{exhibit.name}</h1>
      ) : (
        <h1 className="postTitle">{currentExhibit.title}</h1>
      )}
    </>
  );
};

export default ExhibitList;
